import { useState, useEffect, useRef } from "react";
import { db } from "./firebase";
import { collection, addDoc, Timestamp, doc, setDoc, deleteDoc } from "firebase/firestore";
import { useSwipeable } from "react-swipeable";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import bannerVideo from "./IMG/banner.mp4";
import bannerVideo2 from "./IMG/banner2.mp4";
import videoMobile from "./IMG/videomobile.mp4";
import bannerMobile2 from "./IMG/bannermobile2.mp4";
import bannerMobile3 from "./IMG/bannermobile3.mp4";
import bannerMobile4 from "./IMG/bannermobile4.mp4";
import videoMobile2 from "./IMG/videomobile2.mp4";
import banner2 from "./IMG/banner2.png";
import banner3 from "./IMG/banner3.png";
import banner4 from "./IMG/banner4.png";
import banner5 from "./IMG/banner5.png";
import banner6 from "./IMG/banner6.png";
import banner7 from "./IMG/banner7.png";
import banner8 from "./IMG/banner8.png";
import banner9 from "./IMG/banner9.png";
import banner10 from "./IMG/banner10.png";
import banner11 from "./IMG/banner11.png";
import banner12 from "./IMG/banner12.png";
import banner13 from "./IMG/banner13.png";
import banner14 from "./IMG/banner14.png";
import banner15 from "./IMG/banner15.png";
import banner16 from "./IMG/banner16.png";
import banner17 from "./IMG/banner17.png";
import banner18 from "./IMG/banner18.png";
import banner19 from "./IMG/banner19.png";
import banner20 from "./IMG/banner20.png";

import mobile5 from "./IMG/mobile5.png";
import mobile6 from "./IMG/mobile6.png";
import mobile7 from "./IMG/mobile7.png";
import mobile8 from "./IMG/mobile8.png";
import mobile9 from "./IMG/mobile9.png";
import mobile10 from "./IMG/mobile10.png";
import fundo from "./IMG/fundo.png";
import mobile11 from "./IMG/mobile11.png";
import mobile12 from "./IMG/mobile12.png";
import mobile13 from "./IMG/mobile13.png";
import mobile14 from "./IMG/mobile14.png";
import mobile15 from "./IMG/mobile15.png";
import mobile16 from "./IMG/mobile16.png";
import mobile17 from "./IMG/mobile17.png";
import mobile18 from "./IMG/mobile18.png";
import mobile19 from "./IMG/mobile19.png";
import mobile20 from "./IMG/mobile20.png";

import mobile23 from "./IMG/mobile23.png";
import mobile24 from "./IMG/mobile24.png";
import mobile25 from "./IMG/mobile25.png";
import mobile26 from "./IMG/mobile26.png";
import mobile27 from "./IMG/mobile27.png";
import "./App.css";

function App() {
  const [currentBanner4to5, setCurrentBanner4to5] = useState("banner4");
  const [isBanner3Visible, setIsBanner3Visible] = useState(false);
  const [isBanner4Visible, setIsBanner4Visible] = useState(false);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const [currentBanner14to15, setCurrentBanner14to15] = useState("banner14");
  const [transitionDirection4to5, setTransitionDirection4to5] = useState("");
  const [transitionDirection14to15, setTransitionDirection14to15] =
    useState("");

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  // Alternar banners 4 e 5
  const toggleBanner4to5 = (direction) => {
    setTransitionDirection4to5(direction);
    setTimeout(() => {
      setCurrentBanner4to5((prev) =>
        prev === "banner4" ? "banner5" : "banner4"
      );
      setTransitionDirection4to5("");
    }, 150); // Tempo da animação CSS (0.5s)
  };

  // Alternar banners 14 e 15
  const toggleBanner14to15 = (direction) => {
    setTransitionDirection14to15(direction);
    setTimeout(() => {
      setCurrentBanner14to15((prev) =>
        prev === "banner14" ? "banner15" : "banner14"
      );
      setTransitionDirection14to15("");
    }, 200); // Tempo da animação CSS (0.5s)
  };

  // Swipe handlers para os banners 4 e 5
  const swipeHandlers4to5 = useSwipeable({
    onSwipedLeft: () => toggleBanner4to5("left"),
    onSwipedRight: () => toggleBanner4to5("right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Swipe handlers para os banners 14 e 15
  const swipeHandlers14to15 = useSwipeable({
    onSwipedLeft: () => toggleBanner14to15("left"),
    onSwipedRight: () => toggleBanner14to15("right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleClick = () => {
    window.open("https://wa.me/5567981466225", "_blank");
  };

  useEffect(() => {
    const observer3 = new IntersectionObserver(
      ([entry]) => {
        setIsBanner3Visible(entry.isIntersecting);
      },
      { threshold: 0.5 } // 50% do vídeo precisa estar visível
    );

    const observer4 = new IntersectionObserver(
      ([entry]) => {
        setIsBanner4Visible(entry.isIntersecting);
      },
      { threshold: 0.5 } // 50% do vídeo precisa estar visível
    );

    if (videoRef3.current) observer3.observe(videoRef3.current);
    if (videoRef4.current) observer4.observe(videoRef4.current);

    return () => {
      if (videoRef3.current) observer3.unobserve(videoRef3.current);
      if (videoRef4.current) observer4.unobserve(videoRef4.current);
    };
  }, []);

  useEffect(() => {
    if (videoRef3.current) {
      if (isBanner3Visible) {
        videoRef3.current.play();
      } else {
        videoRef3.current.pause();
        videoRef3.current.currentTime = 0;
      }
    }
  }, [isBanner3Visible]);

  useEffect(() => {
    if (videoRef4.current) {
      if (isBanner4Visible) {
        videoRef4.current.play();
      } else {
        videoRef4.current.pause();
        videoRef4.current.currentTime = 0;
      }
    }
  }, [isBanner4Visible]);

   // Função para registrar uma visita no Firestore
   const recordLinkXVisit = async () => {
    try {
      await addDoc(collection(db, "linkXPageVisits"), {
        timestamp: Timestamp.now(),
      });
      console.log("Visita do Link-X registrada");
    } catch (error) {
      console.error("Erro ao registrar visita do Link-X:", error);
    }
  };

  // Função para registrar um usuário ativo
  const registerLinkXActiveUser = async () => {
    const userRef = doc(collection(db, "linkXActiveUsers"), `user_${Date.now()}`);
    await setDoc(userRef, {
      lastActive: Timestamp.now(),
    });

    // Atualiza o campo lastActive a cada 5 segundos
    const heartbeatInterval = setInterval(async () => {
      await setDoc(userRef, { lastActive: Timestamp.now() }, { merge: true });
    }, 5000);

    // Remove o documento ao fechar a janela
    window.addEventListener("beforeunload", async () => {
      clearInterval(heartbeatInterval);
      await deleteDoc(userRef); // Remove o documento quando o usuário sair
    });
  };

  // Função para registrar um clique no botão
  const recordLinkXClick = async (buttonName) => {
    try {
      await addDoc(collection(db, "linkXClickMetrics"), {
        button: buttonName,
        timestamp: Timestamp.now(),
      });
      console.log(`Clique registrado para o botão do Link-X: ${buttonName}`);
    } catch (error) {
      console.error("Erro ao registrar clique no Link-X:", error);
    }
  };

  useEffect(() => {
    recordLinkXVisit(); // Registra uma visita quando a página carrega
    registerLinkXActiveUser(); // Registra o usuário como ativo
  }, []);

  return (
    <div className="AppContainer">
      {/* Banners iniciais */}
      <div className="Banner">
        <video
          src={bannerVideo}
          className="BannerVideo"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>

      <div className="Banner2">
        <img className="banner2-img" src={banner2} alt="Banner 2" />
      </div>

      <div className="Banner3">
        <img className="banner3-img" src={banner3} alt="Banner 3" />
      </div>

     
      {/* Banners 4 e 5 */}
      <div
        className={`BannerSwitcher ${transitionDirection4to5}`}
        {...swipeHandlers4to5}
      >
        <div
          className="BannerZone LeftZone"
          onClick={() => toggleBanner4to5("left")}
        ></div>
        <img
          className={`BannerImage ${currentBanner4to5}`}
          src={currentBanner4to5 === "banner4" ? banner4 : banner5}
          alt={`Banner ${currentBanner4to5 === "banner4" ? "4" : "5"}`}
        />
        <div
          className="BannerZone RightZone"
          onClick={() => toggleBanner4to5("right")}
        ></div>
        <div className="ButtonOverlay">
          <button className="ActionButton" onClick={() => {
            recordLinkXClick("ASSINAR DESKTOP BASIC");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
            QUERO ASSINAR
          </button>
          <button className="ActionButton" onClick={() => {
            recordLinkXClick("ASSINAR DESKTOP PREMIUM");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
            QUERO ASSINAR
          </button>
        </div>
      </div>

      <div className="Banner6">
        <img className="banner6-img" src={banner6} alt="Banner 6" />
      </div>

      <div className="Banner7">
        <img className="banner7-img" src={banner7} alt="Banner 7" />
      </div>

      <div className="Banner8">
        <img className="banner8-img" src={banner8} alt="Banner 8" />
        <div className="ButtonOverlay8">
          <button className="ActionButton8"  onClick={() => {
            recordLinkXClick(" ENTRAR EM CONTATO DESKTOP");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
            ENTRAR EM CONTATO
          </button>
        </div>
      </div>

      <div className="Banner9">
        <img className="banner9-img" src={banner9} alt="Banner 9" />
      </div>

      <div className="Banner10">
        <img className="banner10-img" src={banner10} alt="Banner 10" />
      </div>


      <div className="Banner11">
        <img className="banner11-img" src={banner11} alt="Banner 11" />
        <div className="ButtonOverlay11">
          <button className="ActionButton11"  onClick={() => {
            recordLinkXClick(" SAIBA MAIS DESKTOP");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
           SAIBA MAIS
          </button>
        </div>
      </div>

      <div className="Banner12">
        <img className="banner12-img" src={banner12} alt="Banner 12" />
      </div>

      <div className="Banner13">
        <img className="banner13-img" src={banner13} alt="Banner 13" />
      </div>

      {/* Banners 14 e 15 */}
      <div
        className={`BannerSwitcher ${transitionDirection14to15}`}
        {...swipeHandlers14to15}
      >
        <div
          className="BannerZone LeftZone"
          onClick={() => toggleBanner14to15("left")}
        ></div>
        <img
          src={currentBanner14to15 === "banner14" ? banner14 : banner15}
          alt={`Banner ${currentBanner14to15 === "banner14" ? "14" : "15"}`}
          className="BannerImage2"
        />
        <div
          className="BannerZone RightZone"
          onClick={() => toggleBanner14to15("right")}
        ></div>
      </div>

      <div className="Banner16">
        <img className="banner16-img" src={banner16} alt="Banner 16" />
      </div>

      <div className="Banner17">
        <img className="banner17-img" src={banner17} alt="Banner 17" />
      </div>

      <div className="Banner18">
        <img className="banner18-img" src={banner18} alt="Banner 18" />
      </div>

      <div className="Banner19">
        <img className="banner19-img" src={banner19} alt="Banner 19" />
      </div>

      <div className="Banner20">
        <img className="banner20-img" src={banner20} alt="Banner 20" />
      </div>
      <div className="Banner2">
        <video
          src={bannerVideo2}
          className="BannerVideo2"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>

      <div className="videoMobile">
        <video
          src={videoMobile}
          className="videoMobile"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>

      <div className="bannerMobile2">
        <video
          src={bannerMobile2}
          className="bannerMobile2"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>

      <div className="bannerMobile3">
        <video
          ref={videoRef3}
          src={bannerMobile3}
          className="bannerMobile3"
          muted
          playsInline
        />
      </div>



  
<div className="Mobile5">
  <img className="mobile5-img" src={mobile5} alt="mobile 5" />
</div>
<div className="Mobile6">
  <img className="mobile6-img" src={mobile6} alt="mobile 6" />
</div>
<div className="Mobile7">
  <img className="mobile7-img" src={mobile7} alt="mobile 7" />
</div>
<div className="Mobile8">
  <img className="mobile8-img" src={mobile8} alt="mobile 8" />
</div>
<div className="Mobile9">
  <img className="mobile9-img" src={mobile9} alt="mobile 9" />
</div>
<div className="Mobile10">
  <img className="mobile10-img" src={mobile10} alt="mobile 10" />
</div>

<div className="fundo">
  <img className="fundo-img" src={fundo} alt="fundo" />
  <div className="ButtonOverlayfundo">
          <button className="ActionButtonfundo"  onClick={() => {
            recordLinkXClick("  QUERO ASSINAR MOBILE");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
          QUERO ASSINAR
          </button>
        </div>
</div>

<div className="Mobile11">
  <img className="mobile11-img" src={mobile11} alt="mobile 11" />
</div>
<div className="Mobile12">
  <img className="mobile12-img" src={mobile12} alt="mobile 12" />
</div>
<div className="Mobile13">
  <img className="mobile13-img" src={mobile13} alt="mobile 13" />
</div>


<div className="Mobile14">
  <img className="mobile14-img" src={mobile14} alt="mobile 14" />
  <div className="ButtonOverlay14">
          <button className="ActionButton14"  onClick={() => {
            recordLinkXClick("  Trafego Pago MOBILE ");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
          QUERO ASSINAR
          </button>
        </div>
</div>


<div className="Mobile15">
  <img className="mobile15-img" src={mobile15} alt="mobile 15" />
  <div className="ButtonOverlay15">
          <button className="ActionButton15" onClick={() => {
            recordLinkXClick("LINK-X MOBILE");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
          QUERO ASSINAR
          </button>
        </div>
</div>

<div className="Mobile16">
  <img className="mobile16-img" src={mobile16} alt="mobile 16" />
</div>
<div className="Mobile17">
  <img className="mobile17-img" src={mobile17} alt="mobile 17" />
</div>
<div className="Mobile18">
  <img className="mobile18-img" src={mobile18} alt="mobile 18" />
</div>

<div className="Mobile19">
  <img className="mobile19-img" src={mobile19} alt="mobile 19" />
</div>

<div className="Mobile20">
  <img className="mobile20-img" src={mobile20} alt="mobile 20" />
  <div className="ButtonOverlay20">
    <button className="ActionButton20" onClick={() => {
            recordLinkXClick("ENTRAR EM CONTATO MOBILE");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
     ENTRAR EM CONTATO
    </button>
  </div>
</div>


<div className="bannerMobile4">
        <video
          ref={videoRef4}
          src={bannerMobile4}
          className="bannerMobile4"
          muted
          playsInline
        />
      </div>


<div className="Mobile23">
  <img className="mobile23-img" src={mobile23} alt="mobile 23" />
</div>
<div className="Mobile24">
  <img className="mobile24-img" src={mobile24} alt="mobile 24" />
</div>


<div className="Mobile25">
  <img className="mobile25-img" src={mobile25} alt="mobile 25" />
  <div className="ButtonOverlay25">
    <button className="ActionButton25" onClick={() => {
            recordLinkXClick("SAIBA MAIS MOBILE");
            window.open("https://wa.me/5567981466225", "_blank");
          }}>
      SAIBA MAIS
    </button>
  </div>
</div>


<div className="Mobile26">
  <img className="mobile26-img" src={mobile26} alt="mobile 26" />
</div>

<div className="Mobile27">
  <img className="mobile27-img" src={mobile27} alt="mobile 27" />
</div>

<div className="videoMobile2">
        <video
          src={videoMobile2}
          className="videoMobile2"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>

      
      <footer className="Footer">
      <div className="FooterContent">
        <p>© Especialista em Lojas Virtuais</p>
        <div className="FooterIcons">
          <span
            onClick={() => {
              recordLinkXClick("Whatsapp ÍCONE");
              window.open("https://wa.me/5567981466225", "_blank");
            }}
            style={{ cursor: "pointer" }}
          >
            <FaWhatsapp className="Icon" />
          </span>
          <span
            onClick={() => {
              recordLinkXClick("Instagram ÍCONE");
              window.open("https://www.instagram.com/codex.corp/", "_blank");
            }}
            style={{ cursor: "pointer" }}
          >
            <FaInstagram className="Icon" />
          </span>
        </div>
      </div>
    </footer>



    </div>
  );
}

export default App;
