import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAK0TEkJnpOQnPcqIE_RFyUD4Hd4f14hFI",
    authDomain: "profitroupas.firebaseapp.com",
    projectId: "profitroupas",
    storageBucket: "profitroupas.appspot.com",
    messagingSenderId: "707407935198",
    appId: "1:707407935198:web:67db8e22bce9fa0d06b44f",
    measurementId: "G-B8HJXDBR6J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
